import React from "react";
import Homepage from "./components/Homepage";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import About from "./components/About";
import Galerija from "./components/Galerija";


function Index() {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<Homepage/>}/>
                <Route exact path="/galerija" element={<Galerija/>}/>
                <Route exact path="/about" element={<About/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default Index;
